import { css } from '@emotion/css'
import { Components } from './Text.props'

type TextStyleProps = {
  variant: Components
  isBold?: boolean
}

const getStyleType = (variant: Components) => {
  const fontType = {
    h1: css`
      font-size: 3rem;
    `,
    h2: css`
      font-size: 2.5rem;
    `,
    h3: css`
      font-size: 2rem;
    `,
    h4: css`
      font-size: 1.5rem;
      line-height: 120%;
    `,
    h5: css`
      font-size: 1.25rem;
    `,
    h6: css`
      font-size: 1rem;
    `,
    span: css`
      font-size: 1rem;
    `,
  }

  return fontType[variant] ?? fontType.span
}

export const TextStyles = ({ variant, isBold }: TextStyleProps) => css`
  ${getStyleType(variant)}

  font-weight: ${isBold ? 600 : 400};
`
