import { FunctionalComponent, h } from 'preact'
import { FormProvider } from '../FormProvider'
import { FormProps } from './DynamicForm.props'

const DynamicForm: FunctionalComponent<FormProps> = ({
  children,
  onSubmit,
  validation,
}) => {
  return (
    <FormProvider onSubmit={onSubmit} validation={validation}>
      {children}
    </FormProvider>
  )
}

export default DynamicForm
