import { css } from '@emotion/css'
import { ErrorMessage } from '@presentation/components'
import { useField } from '@presentation/providers'
import { Utils } from '@data/utils'
import * as PReact from 'preact'
import * as React from 'preact/hooks'
import { InputProps } from './Input.props'
import { Root } from './Input.styles'

const Input: PReact.FunctionalComponent<InputProps> = ({
  label,
  name = 'input',
  onChange,
  placeholder,
  className,
  parentName,
  ...props
}) => {
  const { addField } = useField(name)
  const inputRef = React.useRef()

  const handleChange: PReact.JSX.GenericEventHandler<
    HTMLInputElement
  > = event => {
    onChange?.(event)
  }

  React.useEffect(() => {
    addField({
      ref: inputRef.current,
    })
  }, [])

  const classes = css([Root, className])

  return (
    <div class={classes}>
      <div class="container-wrapper">
        <label htmlFor={name} class="label-content">
          {label}
        </label>

        <input
          type="text"
          {...props}
          ref={inputRef}
          onInput={handleChange}
          id={name}
          name={name}
          placeholder={placeholder}
          class="container-wrapper--input"
          data-action={Utils.dataNames.renderDataName(name, parentName)}
        />
      </div>

      <ErrorMessage name={name} />
    </div>
  )
}

export default Input
