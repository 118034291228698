import ArrowDown from './svgs/arrow-down'
import ArrowLeft from './svgs/arrow-left'
import Close from './svgs/close'
import Help from './svgs/help'
import Warning from './svgs/warning'

export default {
  ArrowDown,
  ArrowLeft,
  Close,
  Help,
  Warning,
}
