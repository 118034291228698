import * as PReact from 'preact'
import { useState } from 'preact/hooks'
import { Input } from '../Input'
import { InputHeightProps } from './InputHeight.props'

const InputHeight: PReact.FunctionalComponent<InputHeightProps> = ({
  value,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(value)

  const handleChange = event => {
    let value = event.target.value

    value = value
      .replace(/\D/g, '')
      .replace(/(\d{1})(\d)/, '$1.$2')
      .replace(/(.\d{2})\d+?$/, '$1')

    if (value !== event.target.value) {
      event.target.value = value
    }

    setInputValue(value)
  }

  return (
    <Input
      placeholder="Metros"
      {...props}
      type="tel"
      value={inputValue}
      onChange={handleChange}
    />
  )
}

export default InputHeight
