import { h, FunctionalComponent } from 'preact'

import { FormContext } from '../FormProvider'
import { FormProps } from './Form.props'

const Form: FunctionalComponent<FormProps> = ({ children, ...props }) => {
  return (
    <FormContext.Consumer>
      {({ onSubmitForm }) => (
        <form {...props} onSubmit={onSubmitForm}>
          {children}
        </form>
      )}
    </FormContext.Consumer>
  )
}

export default Form
