import * as PReact from 'preact'
import { useEffect, useRef } from 'preact/hooks'
import { css } from '@emotion/css'
import { Utils } from '@data/utils'

import { useForm } from '@presentation/providers'

import styles from './Checkbox.styles'
import { CheckboxProps } from './Checkbox.props'

const Checkbox: PReact.FunctionalComponent<CheckboxProps> = ({
  label,
  name = 'checkbox',
  className,
  parentName,
  ...props
}) => {
  const { addField } = useForm()

  const inputRef = useRef(null)

  useEffect(() => {
    addField({
      ref: inputRef.current,
      type: inputRef.current.type,
    })
  }, [])

  const classes = css([styles.root, className])

  return (
    <label className={classes}>
      <input
        {...props}
        type="checkbox"
        className={styles.input}
        name={name}
        ref={inputRef}
        data-action={Utils.dataNames.renderDataName(name, parentName)}
      />
      {label}
    </label>
  )
}

export default Checkbox
