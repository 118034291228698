import { h, FunctionalComponent } from 'preact'
import { useEffect, useState } from 'preact/hooks'

type withModalProps = any

export const withModal = (
  WrappedComponent: FunctionalComponent<withModalProps>,
) =>
  function Component(props: withModalProps) {
    const { open } = props
    const [show, setShow] = useState(false)

    useEffect(() => {
      setTimeout(
        () => {
          setShow(open)
        },
        open ? 0 : 500,
      )
    }, [open])

    return show ? <WrappedComponent {...props} /> : null
  }

export default withModal
