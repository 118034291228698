import { useEffect, useState } from 'preact/hooks'

interface WindowSize {
  height?: number
  width?: number
}

const useWindowSize = (): WindowSize => {
  const [windowSize, setWindowSize] = useState({})

  function changeWindowSize() {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight })
  }

  useEffect(() => {
    window.addEventListener('resize', changeWindowSize)
    changeWindowSize()

    return () => {
      window.removeEventListener('resize', changeWindowSize)
    }
  }, [])

  return windowSize
}

export default useWindowSize
