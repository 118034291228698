import { css } from '@presentation/styles'

export default {
  root: css`
    background-color: #eb615b;
    width: 100%;
  `,
  input: ({ min, max, value }) => {
    const range = `calc(${max} - ${min})`
    const ratio = `calc((${value} - ${min}) / ${range})`
    const sx = `calc(${ratio} * 100%)`

    return css`
      &,
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
      }

      margin: 0;
      padding: 0;
      width: 100%;
      height: 24px;
      background: transparent;

      &::-webkit-slider-runnable-track {
        box-sizing: border-box;
        border: none;
        width: 100%;
        height: 2px;
        background: linear-gradient(#090a0b, #090a0b) 0 / ${sx} 100% no-repeat
          #d0d4dc;
      }
      &::-moz-range-track {
        box-sizing: border-box;
        border: none;
        width: 100%;
        height: 2px;
        background: #d0d4dc;
      }
      &::-ms-track {
        box-sizing: border-box;
        border: none;
        width: 100%;
        height: 2px;
        background: #d0d4dc;
      }

      &::-moz-range-progress {
        height: 2px;
        background: #d0d4dc;
      }
      &::-ms-fill-lower {
        height: 2px;
        background: #d0d4dc;
      }

      &::-webkit-slider-thumb {
        margin-top: -10px;
        box-sizing: border-box;
        border: none;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #090a0b;
      }
      &::-moz-range-thumb {
        box-sizing: border-box;
        border: none;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #090a0b;
      }
      &::-ms-thumb {
        margin-top: 0;
        box-sizing: border-box;
        border: none;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: #090a0b;
      }

      &::-ms-tooltip {
        display: none;
      }
    `
  },
  range: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 8px;
    width: 100%;

    span {
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      color: #505969;
      cursor: pointer;

      &:hover {
        color: #090a0b;
      }
    }
  `,
}
