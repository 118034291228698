import { Gender } from '@data/models'
import { Utils } from '@data/utils'
import {
  Button,
  Checkbox,
  Icons,
  InputBirthdate,
  InputHeight,
  InputWeight,
  Select,
  Text,
} from '@presentation/components'
import { Form } from '@presentation/providers'
import * as PReact from 'preact'
import * as React from 'preact/hooks'
import { GenderModal } from '../../components'
import styles from './FormContent.styles'

type FormContentProps = {
  disabled?: boolean
}

const genderOptions = [
  {
    value: Gender.MALE,
    name: 'Masculino',
  },
  {
    value: Gender.FEMALE,
    name: 'Feminino',
  },
]

const ACTIONS = {
  SIMULATION_FORM: Utils.actions.simulation.WIDGET_SIMULATION_FORM,
}

const FormContent: PReact.FunctionalComponent<FormContentProps> = ({
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = React.useState(false)

  return (
    <div className={styles.root}>
      <Form className={styles.form}>
        <Text Component="h2" variant="h4" isBold>
          Simule seu seguro em poucos cliques
        </Text>
        <div>
          <div className={styles.inputForm}>
            <InputBirthdate
              className={styles.birthDate}
              name="birthDate"
              label="Data de nascimento"
              parentName={ACTIONS.SIMULATION_FORM}
            />

            <Select
              name="gender"
              label="Sexo"
              labelRightIcon={
                <i
                  title="Porque apenas essas duas?"
                  className="label-content-icon"
                  onClick={() => setIsOpen(true)}
                >
                  <Icons.Help />
                </i>
              }
              options={genderOptions}
              parentName={ACTIONS.SIMULATION_FORM}
            />
          </div>

          <div className={styles.inputForm}>
            <InputWeight
              name="weight"
              label="Peso"
              parentName={ACTIONS.SIMULATION_FORM}
            />
            <InputHeight
              name="height"
              label="Altura"
              parentName={ACTIONS.SIMULATION_FORM}
            />
          </div>

          <Checkbox
            name="isSmoker"
            label="Fumei produtos com nicotina nos últimos 24 meses."
            parentName={ACTIONS.SIMULATION_FORM}
          />
        </div>

        <Button
          type="submit"
          disabled={disabled}
          fullWidth
          parentName={ACTIONS.SIMULATION_FORM}
          data-event-name="widget:calculadora:continuar"
        >
          {disabled ? 'Processando...' : 'Continuar'}
        </Button>
      </Form>

      <GenderModal open={isOpen} onClose={() => setIsOpen(false)} />
    </div>
  )
}

export default FormContent
