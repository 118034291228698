export enum CoveragesCode {
  SEGURO_DE_VIDA = '1001',
  ASSISTENCIA_FUNERAL = '1002',
  INVALIDEZ = '1003',
  DOENCAS_GRAVES = '1004',
  DOENCAS_GRAVES_10 = '1005',
  DIH = '1006',
}

export const CoveragesInfo = {
  [CoveragesCode.SEGURO_DE_VIDA]: {
    slug: 'death-coverage',
  },
  [CoveragesCode.ASSISTENCIA_FUNERAL]: {
    slug: 'funeral-assistance',
  },
  [CoveragesCode.INVALIDEZ]: {
    slug: 'disability',
  },
  [CoveragesCode.DOENCAS_GRAVES]: {
    slug: 'critical-diseases',
  },
  [CoveragesCode.DOENCAS_GRAVES_10]: {
    slug: 'critical-diseases',
  },
}
