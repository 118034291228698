import * as React from 'preact'

const Help: React.FunctionalComponent = () => {
  return (
    <svg viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 3.78809C7.02944 3.78809 3 7.81752 3 12.7881C3 17.7586 7.02944 21.7881 12 21.7881C16.9706 21.7881 21 17.7586 21 12.7881C21 7.81752 16.9706 3.78809 12 3.78809ZM1 12.7881C1 6.71295 5.92487 1.78809 12 1.78809C18.0751 1.78809 23 6.71295 23 12.7881C23 18.8632 18.0751 23.7881 12 23.7881C5.92487 23.7881 1 18.8632 1 12.7881Z"
        fill="#A3AAB9"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 8.78809C11.4477 8.78809 11 9.2358 11 9.78809V10.2881C11 10.8404 10.5523 11.2881 10 11.2881C9.44772 11.2881 9 10.8404 9 10.2881V9.78809C9 8.13123 10.3431 6.78809 12 6.78809C13.6569 6.78809 15 8.13123 15 9.78809V10.6701C15 11.734 14.3989 12.7067 13.4472 13.1825C13.1731 13.3196 13 13.5997 13 13.9061V14.2881C13 14.8404 12.5523 15.2881 12 15.2881C11.4477 15.2881 11 14.8404 11 14.2881V13.9061C11 12.8421 11.6011 11.8695 12.5528 11.3937C12.8269 11.2566 13 10.9765 13 10.6701V9.78809C13 9.2358 12.5523 8.78809 12 8.78809ZM12 16.2881C12.5523 16.2881 13 16.7358 13 17.2881V17.7881C13 18.3404 12.5523 18.7881 12 18.7881C11.4477 18.7881 11 18.3404 11 17.7881V17.2881C11 16.7358 11.4477 16.2881 12 16.2881Z"
        fill="#A3AAB9"
      />
    </svg>
  )
}

export default Help
