import * as PReact from 'preact'
import * as React from 'preact/hooks'
import { Input } from '../Input'
import { InputBirthdateProps } from './InputBirthdate.props'

const InputBirthdate: PReact.FunctionalComponent<InputBirthdateProps> = ({
  value,
  ...props
}) => {
  const [inputValue, setInputValue] = React.useState(value)

  const handleChange = (event: any) => {
    let value = event.target.value

    value = value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\/\d{4})\d+?$/, '$1')

    if (value !== event.target.value) {
      event.target.value = value
    }

    setInputValue(value)
  }

  return (
    <Input
      {...props}
      placeholder="DD/MM/AAAA"
      type="tel"
      value={inputValue}
      onChange={handleChange}
    />
  )
}

export default InputBirthdate
