export const getFieldValueByType = (type?: string, refValue?: any) => {
  if (!type || !refValue) {
    return ''
  }

  const typeValue = {
    checkbox: refValue.checked,
    default: refValue.value,
  }

  return typeValue[type] ?? typeValue.default
}
