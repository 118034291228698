import { Utils } from '@data/utils'
import * as PReact from 'preact'
import { TextProps } from './Text.props'
import { TextStyles } from './Text.styles'

const Text: PReact.FunctionalComponent<TextProps> = ({
  Component = 'span',
  variant = 'span',
  isBold,
  children,
  className,
  parentName,
  title,
}) => {
  return (
    <Component
      className={className}
      class={TextStyles({ variant, isBold })}
      data-action={Utils.dataNames.renderDataName(Component, parentName)}
      title={title}
    >
      {children}
    </Component>
  )
}

export default Text
