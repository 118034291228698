import { useEffect, useMemo, useState } from 'preact/hooks'
import { getFieldValueByType, useForm } from '../FormProvider'
import { UseFieldProps, UseFieldResponse } from './useField.props'

const useField = (name: UseFieldProps): UseFieldResponse => {
  const { addField, fields, submitValues, errors } = useForm()
  const [isDirty, setIsDirty] = useState(false)

  const error = useMemo(
    () => (errors[name] && !isDirty ? errors[name] : undefined),
    [errors[name], isDirty],
  )

  const field = fields.current.find(field => field.name === name)
  const fieldValue = getFieldValueByType(field?.type, field?.ref)

  useEffect(() => {
    if (errors[name]) {
      const valueSubmitted = submitValues[name]
      if (valueSubmitted !== fieldValue) {
        setIsDirty(true)
      } else {
        setIsDirty(false)
      }
    } else {
      setIsDirty(false)
    }
  }, [errors, fieldValue])

  return {
    addField,
    fields,
    isDirty,
    error,
  }
}

export default useField
