import { CoveragePremium } from '@data/models'
import { Utils } from '@data/utils'
import { env } from '@main/config/env'
import { Button, Icons, InputRange, Text } from '@presentation/components'
import { useSimulationProvider } from '@presentation/providers'
import * as PReact from 'preact'
import * as React from 'preact/hooks'
import styles from './Calculator.styles'
import { getCoverageInfo } from './Calculator.utils'

const ACTIONS = {
  CALCULATOR_PRICE: Utils.actions.calculator.PRICE,
  CALCULATOR_PREMIUM: Utils.actions.calculator.PREMIUM,
  BUTTON_BACK: Utils.actions.calculator.BUTTON_BACK,
  BUTTON_REDIRECT: Utils.actions.calculator.BUTTON_REDIRECT,
}

const Calculator: PReact.FunctionalComponent = () => {
  const { fields, quotation, reset, utms, coverageCode } =
    useSimulationProvider()

  const [selected, setSelected] = React.useState<CoveragePremium>({
    premium: 0,
    price: 0,
  })

  const coverages = React.useMemo(
    () => quotation.coverages2premiums?.[0]?.coverage2premium || [],
    [quotation],
  )

  const min = React.useMemo(() => {
    const firstIndex = 0
    return coverages[firstIndex]?.premium || 0
  }, [coverages])

  const max = React.useMemo(() => {
    const lastIndex = coverages.length - 1
    return coverages[lastIndex]?.premium || 0
  }, [coverages])

  const price = React.useMemo(
    () => Utils.currency.format(selected.price),
    [selected],
  )

  const info = React.useMemo(
    () => getCoverageInfo(coverageCode),
    [coverageCode],
  )

  const premium = React.useMemo(
    () => Utils.currency.formatWithoutCents(selected.premium),
    [selected],
  )

  const handleSelect = React.useCallback(
    (event: any) => {
      const index = Number(event.target.value)
      const selectedPremium = coverages[index]
      if (selectedPremium) setSelected(selectedPremium)
    },
    [coverages],
  )

  React.useEffect(() => {
    if (quotation.coverages2premiums.length) {
      setSelected(quotation.coverages2premiums?.[0]?.coverage2premiumSuggestion)
    }
  }, [quotation])

  const handleRedirectToApplication = () => {
    if (fields && typeof window !== 'undefined') {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const iframeParams = JSON.parse(
        JSON.stringify(Object.fromEntries(urlSearchParams.entries())),
      )

      const widgetOrigin = window.location.hostname
      const params = {
        widgetOrigin,
        ...fields,
        birthDate: Utils.date.stringToDate(fields.birthDate).toISOString(),
        price: selected.price.toString(),
        height: fields.height.toString(),
        weight: fields.weight.toString(),
        isSmoker: fields.isSmoker.toString(),
      }

      // const encoded = Buffer.from(JSON.stringify(params), 'base64')
      const encoded = btoa(JSON.stringify(params))

      const qsParams = {
        widget: encoded,
        widgetOrigin,
        ...iframeParams,
        ...utms,
      }

      const url = `${env.redirectUrl}?${new URLSearchParams(
        qsParams,
      ).toString()}`

      window.open(url, '_blank')
    }
  }

  return (
    <div className={styles.root}>
      <Text className="title" title="Veja seu plano ideal" variant="h4">
        Veja seu plano ideal
      </Text>
      <div className={styles.header}>
        <Text
          title="Sua estimativa de preço"
          variant="span"
          Component="span"
          className="estimative--price"
          parentName={ACTIONS.CALCULATOR_PRICE}
        >
          Sua estimativa de preço
        </Text>
        <Text
          className="price"
          Component="strong"
          title={price}
          parentName={ACTIONS.CALCULATOR_PRICE}
        >
          {price}
          <span>/mês</span>
        </Text>
      </div>

      <div className={styles.content}>
        <Text
          title={info.title}
          Component="h6"
          parentName={ACTIONS.CALCULATOR_PREMIUM}
        >
          {info.description}
        </Text>
        <Text
          title={premium}
          Component="strong"
          parentName={ACTIONS.CALCULATOR_PREMIUM}
        >
          {premium}
        </Text>
        <InputRange
          min={min}
          max={max}
          size={coverages.length - 1}
          value={coverages.findIndex(item => item.premium === selected.premium)}
          onChange={handleSelect}
          onMin={() => setSelected(coverages[0])}
          onMax={() => setSelected(coverages[coverages.length - 1])}
          parentName={ACTIONS.CALCULATOR_PREMIUM}
        />
      </div>

      <div className={styles.info}>
        <Icons.Warning />
        <span>A cobertura e mensalidade estão sujeitos à alterações</span>
      </div>

      <div className={styles.footer}>
        <Button
          title="Voltar"
          onClick={reset}
          variant="transparent"
          parentName={ACTIONS.BUTTON_BACK}
          data-event-name="widget:calculadora:voltar"
        >
          <Icons.ArrowLeft />
          <span>Voltar</span>
        </Button>
        <Button
          title="Iniciar contratação"
          parentName={ACTIONS.BUTTON_REDIRECT}
          onClick={handleRedirectToApplication}
          data-event-name="widget:calculadora:iniciar"
        >
          Iniciar contratação
        </Button>
      </div>
    </div>
  )
}

export default Calculator
