import * as PReact from 'preact'
import { Utils } from '@data/utils'

import { ButtonProps } from './Button.props'
import buttonStyles from './Button.styles'

const Button: PReact.FunctionalComponent<ButtonProps> = ({
  children,
  fullWidth,
  parentName,
  variant = 'contained',
  ...props
}) => {
  const styles = buttonStyles({ fullWidth, variant })

  return (
    <button
      {...props}
      className={styles.root}
      data-action={Utils.dataNames.renderDataName('button', parentName)}
    >
      {children}
    </button>
  )
}

export default Button
