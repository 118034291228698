import {
  CoveragesCode,
  ExpressQuotationRequest,
  ExpressQuotationResponse,
  Gender,
} from '@data/models'
import api from './api'

export type CreateQuotationRequest = {
  coverageCode?: CoveragesCode
  birthDate: string
  gender: Gender
  height: number | string
  weight: number | string
  isSmoker: boolean
}

export const QuotationAPI = {
  createQuotation: async (
    params: CreateQuotationRequest,
  ): Promise<ExpressQuotationResponse> => {
    const {
      birthDate,
      gender,
      height,
      weight,
      isSmoker,
      coverageCode = CoveragesCode.SEGURO_DE_VIDA,
    } = params

    const [day, month, year] = birthDate.split('/')

    const request: ExpressQuotationRequest = {
      birthDate: new Date(+year, +month - 1, +day),
      gender,
      height: +height,
      weight: +weight,
      isSmoker,
    }

    const response = await api.post(
      `/quotations?coverage_code=${coverageCode}`,
      request,
    )

    return response.data as ExpressQuotationResponse
  },
}
