import { Utils } from '@data/utils'

const { stringToDate, validate } = Utils.date

const genderValidation = (gender?: string) => {
  let error = undefined

  if (!gender) {
    error = {
      gender: 'Precisa selecionar uma opção',
    }
  }

  return error
}

const weightValidation = (weight?: string) => {
  let error = undefined
  const weightValue = +weight

  if (!weightValue) {
    return (error = {
      weight: 'Campo obrigatório',
    })
  }

  if (weightValue <= 20) {
    error = {
      weight: 'Verifique o seu peso',
    }
  }

  if (weightValue > 300) {
    error = {
      weight: 'Você não pode ter mais de 300kg',
    }
  }

  return error
}

const heightValidation = (height?: string) => {
  let error = undefined
  const heightValue = +height

  if (!heightValue) {
    return (error = {
      height: 'Campo obrigatório',
    })
  }

  if (heightValue < 1) {
    error = {
      height: 'Você deve ter ao menos 1m',
    }
  }

  if (heightValue > 2.5) {
    error = {
      height: 'Você não pode ter mais de 2,50m',
    }
  }

  return error
}

const birthDateValidation = (value?: string) => {
  let error = undefined

  const birthDate = stringToDate(value)
  const minDate = new Date(1900, 0, 1, 0, 0, 0)
  const today = () => new Date(new Date().setHours(0, 0, 0, 0))

  if (!value || !birthDate) {
    return (error = {
      birthDate: 'Campo obrigatório',
    })
  }

  if (
    validate(birthDate).isOlderThan(minDate) ||
    validate(birthDate).isEarlierThan(today()) ||
    !validate(birthDate).isValid()
  ) {
    return (error = {
      birthDate: 'Insira uma data válida',
    })
  }

  const underAge = new Date(today().setFullYear(today().getFullYear() - 18))
  const olderAge = new Date(today().setFullYear(today().getFullYear() - 66))

  if (validate(birthDate).isEarlierThan(underAge)) {
    return (error = {
      birthDate: 'Atualmente asseguramos pessoas maiores que 18 anos',
    })
  }

  if (validate(birthDate).isOlderThan(olderAge)) {
    return (error = {
      birthDate: 'Atualmente asseguramos pessoas menores que 66 anos',
    })
  }

  return error
}

export const validationSchema = (values: { [key: string]: any }) => {
  return {
    ...birthDateValidation(values.birthDate),
    ...genderValidation(values.gender),
    ...heightValidation(values.height),
    ...weightValidation(values.weight),
  }
}
