const envDev = {
  api: 'https://api-widget.azos.dev',
  redirectUrl: 'https://azos.dev',
}

const envProd = {
  api: 'https://api-widget.azos.com.br',
  redirectUrl: 'https://app.azos.com.br',
}

export const env = process.env.NODE_ENV === 'production' ? envProd : envDev
