import { css } from '@emotion/css'
import { ButtonVariants } from './Button.props'

type ButtonStyleProps = {
  fullWidth: boolean
  variant: ButtonVariants
}

const buttonVariant = (variant: ButtonVariants) => {
  const variants = {
    contained: css`
      background-color: #00b000;
      color: white;
    `,
    transparent: css`
      background-color: transparent;
      color: #090a0b;
    `,
  }

  return variants[variant] ?? variants.contained
}

const styles = (props: ButtonStyleProps) => {
  return {
    root: css`
      border: 0;
      cursor: pointer;
      font-size: 1rem;
      min-height: 48px;
      padding: 6px 16px;
      text-align: center;
      width: ${props.fullWidth ? '100%' : 'auto'};

      ${buttonVariant(props.variant)}

      &:disabled {
        background-color: #005700;
        cursor: default;
      }
    `,
  }
}

export default styles
