export const stringToDate = (stringfiedDate: string) => {
  const [day, month, year] = stringfiedDate.split('/')

  return new Date(new Date(+year, +month - 1, +day).setHours(0, 0, 0, 0))
}

export const stringToISO = (stringfiedDate: string) => {
  const [day, month, year] = stringfiedDate.split('/')

  return `${year}-${month}-${day}`
}

export const validate = (date: Date) => {
  const isEarlierThan = (otherDate: Date) =>
    date.getTime() > otherDate.getTime()

  const isOlderThan = (otherDate: Date) => date.getTime() <= otherDate.getTime()

  const isValid = () => date instanceof Date && !isNaN(+date)

  return {
    isEarlierThan,
    isOlderThan,
    isValid,
  }
}
