import { DynamicForm, useSimulationProvider } from '@presentation/providers'
import * as PReact from 'preact'
import { validationSchema } from './Simulation.validation'
import { FormContent } from './views'

const Simulation: PReact.FunctionalComponent = () => {
  const { createQuotation, isLoading } = useSimulationProvider()

  return (
    <DynamicForm onSubmit={createQuotation} validation={validationSchema}>
      <FormContent disabled={isLoading} />
    </DynamicForm>
  )
}

export default Simulation
