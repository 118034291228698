import * as PReact from 'preact'
import App from './app'
import { CoveragesCode } from './data/models'

if (typeof window !== 'undefined') {
  const container = document.getElementById('azos-widget-simulation')

  const coverageCode = container.getAttribute('data-coverage-code') || undefined

  const utms = Object.entries({
    utm_source: container.getAttribute('data-utm-source'),
    utm_campaign: container.getAttribute('data-utm-campaign'),
    utm_medium: container.getAttribute('data-utm-medium'),
  }).reduce((acc, [key, value]) => {
    if (value) acc[key] = value
    return acc
  }, {})

  PReact.render(
    <App utms={utms} coverageCode={coverageCode as CoveragesCode} />,
    container,
  )
}

export default <></>
