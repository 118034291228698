export const format = (value: number): string => {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value)
}

export const formatLongValue = (value: number): string => {
  const [amount] = formatWithoutCents(value).split('.')

  return `${amount} ${getUnitName(value)}`
}

export const formatWithoutCents = (value: number): string => {
  return format(value).replace(/,.+/, '')
}

export const getUnitName = (value: number): string => {
  const nums = format(value).replace('R$ ', '')

  const amount = nums.replace(/,.+/, '')

  const num = amount.replace('.', '').length

  const [first, second] = amount.split('.')

  if ((num >= 4 && num <= 6) || +second) {
    return 'mil'
  } else if (num >= 7) {
    return +first <= 1 ? 'milhão' : 'milhões'
  } else {
    return +first <= 1 ? 'real' : 'reais'
  }
}
