import { Icons } from '@presentation/components'
import { usePrevious, useWindowSize } from '@presentation/hooks'
import * as PReact from 'preact'
import { createPortal } from 'preact/compat'
import { useCallback, useEffect, useMemo, useRef, useState } from 'preact/hooks'

import { ModalProps } from './Modal.props'
import modalStyles from './Modal.styles'

const Modal: PReact.FunctionalComponent<ModalProps> = ({
  open,
  onClose,
  title,
  subtitle,
  showCloseButton,
  children,
  closeOnEsc = true,
  variant = 'bottomDrawer',
  preventScroll = true,
}) => {
  const modalRef = useRef(null)
  const modalContentRef = useRef(null)
  const prevOpen = usePrevious(open)

  const [scrollY, setScrollY] = useState(0)

  if (typeof window === 'undefined') {
    return null
  }

  const { width } = useWindowSize()

  const modalVariant = useMemo(() => {
    if (width && width >= 768 && variant === 'bottomDrawer') return 'drawer'
    return variant
  }, [width, variant])

  useEffect(() => {
    if (preventScroll && (open || prevOpen !== undefined)) {
      setTimeout(() => {
        const body = window.document.body

        if (open) {
          setScrollY(window.pageYOffset)

          if (body.scrollHeight > window.innerHeight && width >= 768) {
            body.style.paddingRight = '15px'
          }

          body.style.overflow = 'hidden'
          body.style.top = `-${window.pageYOffset}px`
          body.style.position = 'fixed'
          body.style.width = '100%'
        } else {
          body.style.overflow = ''
          body.style.paddingRight = ''
          body.style.position = ''
          body.style.width = ''
          body.style.height = ''
          body.style.top = ''
          window.scrollTo(0, scrollY)
        }
      }, 50)
    }
  }, [open, preventScroll]) // eslint-disable-line

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (
        modalContentRef.current &&
        !modalContentRef.current.contains(event.target)
      )
        onClose()
    },
    [modalRef],
  )

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClick)
      return () => {
        document.removeEventListener('mousedown', handleClick)
      }
    }
  }, [open])

  useEffect(() => {
    if (open) {
      modalRef.current?.focus()
    }
  }, [open])

  const stopEventPropagation = (event: MouseEvent | KeyboardEvent) => {
    event.stopPropagation()
  }

  const handleEscKey = (event: KeyboardEvent) => {
    stopEventPropagation(event)
    if (event.key === 'Escape' && closeOnEsc && onClose) {
      onClose()
    }
  }

  const styles = modalStyles({ active: open, variant: modalVariant })

  return createPortal(
    <div
      ref={modalRef}
      tabIndex={-1}
      role="dialog"
      className={styles.root}
      id="widget-simulation-modal"
      onKeyUp={handleEscKey}
    >
      <div className={styles.modalBackground}>
        <div ref={modalContentRef} className={styles.modal}>
          <div className={styles.header}>
            {(title || subtitle) && (
              <div>
                {title && <p className={styles.title}>{title}</p>}
                {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
              </div>
            )}
            {showCloseButton && (
              <button
                type="button"
                onClick={onClose}
                className={styles.exitButton}
              >
                <Icons.Close />
              </button>
            )}
          </div>
          <div className={styles.contentWrapper}>{children}</div>
        </div>
      </div>
    </div>,
    window.document.body,
  )
}

export default Modal
