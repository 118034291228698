import { Modal, withModal } from '@presentation/components'
import * as PReact from 'preact'
import { GenderModalProps } from './GenderModal.props'

const GenderModal: PReact.FunctionalComponent<GenderModalProps> = ({
  open,
  onClose,
}) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      showCloseButton
      title="Porque apenas essas duas?"
    >
      <div>
        Sabemos que existem mais identidades de gênero do que apenas homem e
        mulher. No entanto, o mercado de seguros está aprendendo sobre o impacto
        de outras identidades de gênero no Seguro de Vida. Ao selecionar o sexo
        de acordo com seu registro de nascimento, conseguiremos uma cotação mais
        precisa.{' '}
      </div>
    </Modal>
  )
}

export default withModal(GenderModal)
