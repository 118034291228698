import { CoveragesCode } from '@/data/models'

type CoverageInfo = {
  title: string
  description: string
}

const coverages = {
  [CoveragesCode.SEGURO_DE_VIDA]: 'Seguro de Vida',
  [CoveragesCode.ASSISTENCIA_FUNERAL]: 'Assistência funeral',
  [CoveragesCode.DOENCAS_GRAVES]: 'Doenças graves',
  [CoveragesCode.DOENCAS_GRAVES_10]: 'Doenças graves 10',
  [CoveragesCode.INVALIDEZ]: 'Invalidez Total por Acidente',
  [CoveragesCode.DIH]: 'Diária de Internação Hospitalar',
}

export const getCoverageInfo = (code: CoveragesCode): CoverageInfo => {
  return {
    title: coverages[code],
    description: getCoverageDescription(code),
  }
}

const getCoverageDescription = (code: CoveragesCode) => {
  switch (code) {
    case CoveragesCode.DOENCAS_GRAVES:
    case CoveragesCode.DOENCAS_GRAVES_10:
      return 'Cobertura do seu seguro doenças graves'
    case CoveragesCode.SEGURO_DE_VIDA:
      return 'Cobertura do seu seguro de vida'
    case CoveragesCode.INVALIDEZ:
      return 'Cobertura do seu seguro invalidez'
    case CoveragesCode.DIH:
      return 'Cobertura do seu seguro diária de internação hospitalar'
    default:
      return 'Cobertura do seu seguro'
  }
}
