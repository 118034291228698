import { Main } from '@presentation/pages'
import { SimulationProvider } from '@presentation/providers'
import * as PReact from 'preact'
import { CoveragesCode, UTMs } from './data/models'
import './index.css'

type AppProps = {
  coverageCode?: CoveragesCode
  utms: UTMs
}

const App: PReact.FunctionalComponent<AppProps> = ({
  utms,
  coverageCode = CoveragesCode.SEGURO_DE_VIDA,
}) => {
  return (
    <SimulationProvider utms={utms} coverageCode={coverageCode}>
      <Main />
    </SimulationProvider>
  )
}

export default App
