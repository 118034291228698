import { Utils } from '@data/utils'
import * as PReact from 'preact'
import styles from './InputRange.styles'

type InputRangeProps = PReact.JSX.HTMLAttributes<HTMLInputElement> & {
  min: number
  max: number
  size: number
  name?: string
  parentName?: string
  onMin?(): void
  onMax?(): void
}

const Input: PReact.FunctionalComponent<InputRangeProps> = ({
  className,
  min,
  max,
  size,
  value,
  onMin,
  onMax,
  name = 'range',
  parentName,
  ...rest
}) => {
  return (
    <div style={styles.root} className={className}>
      <input
        type="range"
        {...rest}
        name={name}
        min={0}
        max={size}
        step={1}
        value={value}
        className={styles.input({ min: 0, max: size, value })}
        data-action={Utils.dataNames.renderDataName(name, parentName)}
      />
      <div className={styles.range}>
        <span
          title={`Selecionar ${Utils.currency.format(min)}`}
          onClick={() => onMin?.()}
          data-action={Utils.dataNames.renderDataName(
            `${name}-min-value`,
            parentName,
          )}
        >
          {Utils.currency.formatLongValue(min)}
        </span>
        <span
          title={`Selecionar ${Utils.currency.format(max)}`}
          onClick={() => onMax?.()}
          data-action={Utils.dataNames.renderDataName(
            `${name}-max-value`,
            parentName,
          )}
        >
          {Utils.currency.formatLongValue(max)}
        </span>
      </div>
    </div>
  )
}

export default Input
