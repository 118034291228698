import { CreateQuotationRequest, QuotationAPI } from '@/data/services'
import { ExpressQuotationResponse, SimulationFields } from '@data/models'
import * as PReact from 'preact'
import { createContext } from 'preact'
import * as React from 'preact/hooks'
import { useContext } from 'preact/hooks'
import {
  SimulationContextData,
  SimulationProviderProps,
} from './SimulationProvider.props'

export const SimulationContext = createContext({} as SimulationContextData)

const initialValue: ExpressQuotationResponse = {
  coverages2premiums: [],
}

export const SimulationProvider: PReact.FunctionalComponent<
  SimulationProviderProps
> = ({ children, utms, coverageCode }) => {
  const [quotation, setQuotation] =
    React.useState<ExpressQuotationResponse>(initialValue)
  const [fields, setFields] = React.useState<SimulationFields>(null)

  const [isFilled, setIsFilled] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isError, setIsError] = React.useState<boolean>(false)

  const handleCreateQuotation = React.useCallback(
    async (params: CreateQuotationRequest) => {
      setFields(params)
      setIsLoading(true)
      setIsError(false)
      await QuotationAPI.createQuotation({
        coverageCode,
        ...params,
      })
        .then(data => {
          setQuotation(data)
          setIsFilled(true)
        })
        .catch(() => {
          setIsError(true)
          setIsLoading(false)
        })
    },
    [],
  )

  const value: SimulationContextData = {
    utms,
    coverageCode,
    createQuotation: handleCreateQuotation,
    fields,
    reset: () => {
      setIsFilled(false)
      setIsLoading(false)
    },
    quotation,
    isFilled,
    isLoading,
    isError,
  }

  return (
    <SimulationContext.Provider value={value}>
      {children}
    </SimulationContext.Provider>
  )
}

export const useSimulationProvider = () => {
  const context = useContext(SimulationContext)

  if (!context) {
    throw new Error(
      'The useSimulationProvider should within SimulationProvider',
    )
  }

  return context
}
