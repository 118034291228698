import { css } from '@emotion/css'
import { Calculator, Simulation } from '@presentation/pages'
import { useSimulationProvider } from '@presentation/providers'
import styles from './Main.styles'
import * as React from 'preact/hooks'

const Main = () => {
  const { isFilled } = useSimulationProvider()
  const calculatorRef = React.useRef(null)

  const rootStyle = css([styles.root({ active: isFilled })])

  React.useEffect(() => {
    if (isFilled) {
      calculatorRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }, [isFilled])

  return (
    <div className={rootStyle}>
      <div className="main__content">
        <section className="simulation__content">
          <Simulation />
        </section>
        <section className="calculator__content" ref={calculatorRef}>
          <Calculator />
        </section>
      </div>
    </div>
  )
}

export default Main
