import { css } from '@emotion/css'

type ContainerStyleProps = {
  maxWidth: boolean
}

export const generalStyles = {
  root: css`
    display: flex;
    flex-direction: column;
    position: relative;

    .label-content {
      display: flex;
      align-items: center;

      label {
        font-size: 14px;
        width: initial;
        margin-bottom: 0px;
      }
      span {
        cursor: pointer;
        width: 18px;
        height: 18px;
      }

      label + span {
        margin-left: 12px;
      }
    }
  `,
  input: css`
    display: none;
  `,
}

export const containerStyles = ({ maxWidth }: ContainerStyleProps) => css`
  min-width: 100px;
  width: ${maxWidth ? '100%' : 'auto'};
  flex: 1;
  outline: none;

  border: 1px solid #d0d4dc;
  border-width: 0 0 2px;
  color: #090a0b;

  font-size: 16px;
  line-height: 100%;
  padding: 7px 0;

  transition: border 0.2s;
  outline: none;

  box-sizing: border-box;
  max-height: 36px;

  margin-bottom: 8px;

  &::placeholder {
    color: #a3aab9;
    font-size: 16px;
    font-weight: 400;
    line-height: 120%;
  }

  transition: border 0.2s;

  &:focus {
    border: 1px solid #090a0b;
  }
`

export const labelStyles = () => css`
  color: #505969;
  font-weight: 600;
  line-height: 125%;
  font-size: 14px;

  display: flex;
  align-items: center;
`
