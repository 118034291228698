const actions = {
  simulation: {
    WIDGET_SIMULATION_FORM: 'b2c-widget-simulation-form',
  },
  calculator: {
    PRICE: 'b2c-widget-calculator-price',
    PREMIUM: 'b2c-widget-calculator-premium',

    BUTTON_BACK: 'b2c-widget-calculator-back',
    BUTTON_REDIRECT: 'b2c-widget-calculator-redirect',
  },
}

export default actions
