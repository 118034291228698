import { css } from '@emotion/css'
import { useField } from '@presentation/providers'
import * as PReact from 'preact'
import { ErrorMessageProps } from './ErrorMessage.props'
import styles from './ErrorMessage.styles'

const ErrorMessage: PReact.FunctionalComponent<ErrorMessageProps> = ({
  name,
  className,
  ...rest
}) => {
  const { error } = useField(name)

  const classes = css([styles.root, className])

  return error ? (
    <span className={classes} {...rest}>
      {error}
    </span>
  ) : null
}

export default ErrorMessage
