import { css } from '@emotion/css'

export const styles = {
  root: css`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
  `,
  form: css`
    height: inherit;
    width: inherit;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
  birthDate: css`
    margin-top: 32px;
  `,
  inputForm: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &:first-child {
      flex-direction: column;
      justify-content: normal;

      & > div + div {
        margin-top: 24px;
      }
    }

    &:not(:first-child) {
      margin-top: 24px;
    }

    &:not(:first-child) {
      & > div {
        width: calc(50% - 6px);
        margin-top: 0px;
        margin-bottom: 0px;

        input {
          width: 100%;
        }
      }
      & > div + div {
      }
    }

    @media (min-width: 600px) {
      &:first-child {
        flex-direction: row;
        justify-content: space-between;

        & > div {
          width: calc(50% - 6px);
          margin-top: 0px;
          margin-bottom: 0px;
        }
        & > div + div {
          margin-left: 16px;
          margin-top: 0px;
        }
      }
    }

    .label-content {
      margin-bottom: 8px;
    }
  `,
}

export default styles
