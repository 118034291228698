import { css } from '@presentation/styles'

export default {
  root: css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    box-sizing: border-box;
    height: 100%;
    width: 100%;

    .title {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 32px;
      line-height: 120%;
    }
  `,
  header: css`
    width: 80%;
    text-align: center;
    margin-bottom: 52px;

    h1 {
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      margin-bottom: 32px;
    }

    .estimative--price {
      display: block;
      color: #505969;
      margin-bottom: 12px;
      line-height: 120%;
    }

    .price {
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 100%;

      span {
        font-weight: normal;
        font-size: 14px;
        color: #505969;
        margin-left: 8px;
        line-height: 120%;
      }
    }
  `,
  content: css`
    width: 100%;
    text-align: center;

    h2 {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      margin-bottom: 20px;
    }

    h6 {
      color: #505969;
      margin-bottom: 12px;
      display: block;
      line-height: 120%;
    }

    strong {
      display: block;
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      margin-bottom: 24px;
      line-height: 100%;
    }

    input {
      width: 100%;
    }

    div {
      margin-bottom: 52px;
    }
  `,
  info: css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;

    background: #f3f4f6;

    margin-bottom: 24px;

    svg {
      flex-shrink: 0;
      color: #a3aab9;
    }

    span {
      font-size: 12px;
      line-height: 18px;
    }

    & > svg + span {
      margin-left: 10px;
    }
  `,
  footer: css`
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button:first-child {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
    }
    button:last-child {
      flex: 1;
      margin-left: 12px;
    }
  `,
}
