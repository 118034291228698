import { css } from '@emotion/css'

export default {
  root: css`
    display: flex;
    align-items: center;
    margin: 24px 0;

    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  `,

  input: css`
    appearance: none;
    margin-right: 16px;
    font: inherit;
    color: currentColor;
    width: 24px;
    height: 24px;
    border: 3px solid currentColor;
    border-radius: 4px;
    display: grid;
    place-content: center;
    &:checked {
      background: black;
    }
    &::before {
      content: '';
      width: 1rem;
      height: 1rem;
      clip-path: polygon(44% 66%, 19% 42%, 11% 52%, 45% 81%, 96% 29%, 88% 21%);
      transform: scale(0);
      background-color: white;
      transition: all 0.1s;
    }
    &:checked::before {
      transform: scale(1);
    }
    &:disabled {
      color: #d0d4dc;
      cursor: not-allowed;
      &:checked {
        background: #d0d4dc;
        &::before {
          background-color: white;
        }
      }
    }
  `,
}
