import * as React from 'preact'

const Close: React.FunctionalComponent = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.8569 4.14307L10.9997 11.0002M8.85686 13.1431L4.14258 17.8574M4.14258 4.14307L17.8569 17.8574"
        stroke="#090A0B"
        strokeWidth="2"
        strokeLinecap="round"
        fill-rule="evenodd"
        clip-rule="evenodd"
      />
    </svg>
  )
}

export default Close
