import * as React from 'preact'

const ArrowDown: React.FunctionalComponent = () => {
  return (
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.21967 0.21967C0.512563 -0.0732233 0.987437 -0.0732233 1.28033 0.21967L6 4.93934L10.7197 0.21967C11.0126 -0.0732233 11.4874 -0.0732233 11.7803 0.21967C12.0732 0.512563 12.0732 0.987437 11.7803 1.28033L6.53033 6.53033C6.23744 6.82322 5.76256 6.82322 5.46967 6.53033L0.21967 1.28033C-0.0732233 0.987437 -0.0732233 0.512563 0.21967 0.21967Z"
        fill="#090A0B"
      />
    </svg>
  )
}

export default ArrowDown
