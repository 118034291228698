import { css } from '@emotion/css'

export const Root = css`
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;

  .container-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;

    &--input {
      border: 1px solid #d0d4dc;
      border-width: 0 0 2px;
      color: #090a0b;

      font-size: 16px;
      line-height: 100%;
      padding: 8px 0;

      transition: border 0.2s;
      outline: none;

      box-sizing: border-box;
      max-height: 36px;
      margin-bottom: 8px;

      &::placeholder {
        color: #a3aab9;
        font-size: 16px;
        font-weight: 400;
        line-height: 120%;
      }
    }

    .label-content {
      color: #505969;
      font-size: 14px;
      font-weight: 600;
      line-height: 125%;

      display: flex;
      align-items: center;
    }
  }
`
