import { h, FunctionalComponent } from 'preact'
import { useState } from 'preact/hooks'

import { Input } from '../Input'

import { InputWeightProps } from './InputWeight.props'

const InputWeight: FunctionalComponent<InputWeightProps> = ({
  value,
  ...props
}) => {
  const [inputValue, setInputValue] = useState(value)

  const handleChange = event => {
    let value = event.target.value

    value = value.replace(/\D/g, '').replace(/(\d{3})\d+?$/, '$1')

    if (value !== event.target.value) {
      event.target.value = value
    }

    setInputValue(value)
  }

  return (
    <Input
      placeholder="kg"
      {...props}
      type="tel"
      value={inputValue}
      onChange={handleChange}
    />
  )
}

export default InputWeight
