import { isEmptyObj } from '@data/utils'
import { createContext, FunctionalComponent } from 'preact'
import { useCallback, useContext, useRef, useState } from 'preact/hooks'
import {
  EventProps,
  FieldType,
  FormContextData,
  FormProviderProps,
} from './FormProvider.props'
import { getFieldValueByType } from './utils'

export const FormContext = createContext({} as FormContextData)

export const FormProvider: FunctionalComponent<FormProviderProps> = ({
  children,
  onSubmit,
  validation,
}) => {
  const fields = useRef<FieldType[]>([])
  const [submitValues, setSubmitValues] = useState<{ [key: string]: any }>({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errors, setErrors] = useState({})

  const addField = (field: FieldType) => {
    fields.current.push({
      ...field,
      name: field.name ?? field.ref.name,
      type: field.type ?? field.ref.localName,
    })
  }

  const getFields = () => {
    return fields.current.reduce(
      (acc, actual) => ({
        ...acc,
        [actual.name]: getFieldValueByType(actual.type, actual.ref),
      }),
      {},
    )
  }

  const onSubmitForm = async (event: EventProps) => {
    event.preventDefault()
    setIsSubmitting(true)
    const values = getFields()
    setSubmitValues(values)

    if (!hasValidationErrors()) {
      return
    }

    await onSubmit(values)

    setIsSubmitting(false)
  }

  const hasValidationErrors = useCallback(() => {
    const errorValues = validation(getFields())
    setErrors(errorValues)
    return isEmptyObj(errorValues)
  }, [])

  const value = {
    addField,
    fields,
    submitValues,
    onSubmitForm,
    isSubmitting,
    errors,
  }

  return <FormContext.Provider value={value}>{children}</FormContext.Provider>
}

export const useForm = () => {
  const context = useContext(FormContext)

  if (!context) {
    throw new Error('The useForm should within FormProvider')
  }

  return context
}
