import { css, keyframes } from '@emotion/css'

type StyleRootProps = {
  active: boolean
}

const transitionToLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
`

const transitionToRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
`

export default {
  root: ({ active }: StyleRootProps) => css`
    width: 100%;
    height: 100%;
    overflow: hidden;

    > div.main__content {
      width: inherit;
      height: inherit;

      transition: 0.4s;
      white-space: nowrap;

      > section {
        display: inline-flex;
        vertical-align: top;
        height: 100%;
        width: 100%;
        white-space: normal;
      }

      > section.simulation__content {
        animation: ${transitionToLeft} 0.2s linear forwards;
        display: ${active ? 'none' : 'visible'};
      }

      > section.calculator__content {
        animation: ${transitionToRight} 0.2s linear forwards;
        display: ${active ? 'visible' : 'none'};
      }
    }
  `,
}
