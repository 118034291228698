import { Utils } from '@data/utils'
import { ErrorMessage } from '@presentation/components'
import { useField } from '@presentation/providers'
import * as PReact from 'preact'
import * as React from 'preact/hooks'
import { SelectProps } from './Select.props'
import { containerStyles, generalStyles, labelStyles } from './Select.styles'

const Select: PReact.FunctionalComponent<SelectProps> = ({
  name = 'select',
  options,
  label,
  labelRightIcon,
  maxWidth,
  parentName,
}) => {
  const { addField } = useField(name)

  const [selectValue, setSelectValue] = React.useState<string>('')

  const inputRef = React.useRef(null)

  React.useEffect(() => {
    addField({
      ref: inputRef.current,
      name,
    })
  }, [])

  const handleChange = (event: any) => {
    setSelectValue(event.target.value)
  }

  return (
    <div className={generalStyles.root}>
      <div className="label-content">
        <label className={labelStyles()} htmlFor={name}>
          {label}
        </label>
        {labelRightIcon && <span>{labelRightIcon}</span>}
      </div>
      <select
        ref={inputRef}
        id={name}
        name={name}
        className={containerStyles({ maxWidth })}
        onChange={handleChange}
        value={selectValue}
        data-action={Utils.dataNames.renderDataName(name, parentName)}
      >
        <option value="" disabled selected>
          Selecione
        </option>
        {options.map(option => (
          <option key={option.name} value={option.value}>
            {option.name}
          </option>
        ))}
      </select>

      <ErrorMessage name={name} />
    </div>
  )
}

export default Select
