import { css, keyframes } from '@emotion/css'
import { VariantType } from './Modal.props'

type ModalProps = {
  active: boolean
  variant: VariantType
}

const fadeIn = keyframes`
  0% {
    background: rgba(0, 0, 0, 0);
  }
  100% {
    background: rgba(0, 0, 0, 0.7);
  }
`

const fadeOut = keyframes`
  0% {
    background: rgba(0, 0, 0, 0.7);
  }
  100% {
    background: rgba(0, 0, 0, 0);
  }
`

const quickFadeDown = keyframes`
  0% {
    transform: scale(1);
  }
  99.9% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`

const scaleUp = keyframes`
  0% {
    transform: translateY(520px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`

const scaleDown = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(520px);
    opacity: 0;
  }
`

const scaleToLeft = keyframes`
  0% {
    transform: translateX(520px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`

const scaleToRight = keyframes`
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(520px);
    opacity: 0;
  }
`
const modalTransition = ({ active, variant }: ModalProps) => {
  const transitions = {
    bottomDrawer: css`
      left: 0;

      height: max-content;
      max-height: 95vh;

      border-radius: 16px 16px 0 0;
      padding: 20px;

      animation: ${active ? scaleUp : scaleDown} 0.5s
        cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    `,

    drawer: css`
      right: 0;

      height: 100%;
      border-radius: 0;

      @media (min-width: 600px) {
        width: 520px;
        height: 100vh;
        max-height: 100vh;
        border-radius: 0;
      }

      animation: ${active ? scaleToLeft : scaleToRight} 0.5s
        cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    `,
  }

  return transitions[variant] ?? transitions.drawer
}

const styles = ({ active, variant }: ModalProps) => {
  return {
    root: css`
      position: fixed;
      display: flex;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      transform: scale(1);
      z-index: 1000;

      margin: 0;
      padding: 0;
      font-family: 'Dazzed', sans-serif;

      ${active
        ? ''
        : css`
            animation: ${quickFadeDown} 0s 0.5s linear forwards;
          `}
    `,
    modalBackground: css`
      display: flex;
      width: 100%;
      background: #00000047;
      position: relative;

      background: rgba(0, 0, 0, 0);
      animation: ${active ? fadeIn : fadeOut} 0.5s
        cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
    `,

    modal: css`
      display: flex;
      flex-direction: column;
      padding: 20px;
      position: absolute;
      bottom: 0;

      @media (min-width: 784px) {
        top: 0;
      }

      background: white;
      padding: 16px;

      ${modalTransition({ active, variant })}
    `,

    header: css`
      display: flex;
      align-items: center;
      margin-bottom: 24px;
    `,

    title: css`
      font-size: 18px;
      font-weight: 600;
      margin: 0;
      margin-right: 16px;
      margin-left: 0;
      padding: 0;
    `,

    subtitle: css`
      margin: 8px 16px 0 0;
      padding: 0;
    `,

    exitButton: css`
      cursor: pointer;
      background: #f3f4f6;
      border: 0;
      border-radius: 50%;
      font-size: 24px;
      padding: 0;
      position: relative;
      height: 48px;
      width: 48px;
      margin-left: auto;

      display: flex;
      align-items: center;
      justify-content: center;
    `,

    contentWrapper: css`
      flex: 1;
    `,
  }
}

export default styles
